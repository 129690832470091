import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { CircularProgress, Box, Button  } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function formatUptime(seconds) {
  const days = Math.floor(seconds / (24 * 3600)); // Calculate total days
  const hours = Math.floor((seconds % (24 * 3600)) / 3600); // Calculate remaining hours
  const minutes = Math.floor((seconds % 3600) / 60); // Calculate remaining minutes
  const secs = seconds % 60; // Calculate remaining seconds

  return `${days}d:${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}

function formatDate(isoString) {
  const date = new Date(isoString);
  return date.toLocaleDateString();  // This will return the date in the user's locale format
}


const UserTerminals = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();


  useEffect(() => {
    const fetchData = async () => {
      const perfilId = localStorage.getItem('perfilId');
      const groupId = localStorage.getItem('groupId');
      const userId = localStorage.getItem('userId');

      let apiEndpoint;

      if (perfilId === '1' || perfilId === '2') {
        apiEndpoint = 'https://echo.tsmpatagonia.com.ar:3001/api/stDeviceIdRouters';
      } else if (perfilId === '3') {
        apiEndpoint = `https://echo.tsmpatagonia.com.ar:3001/api/stDeviceIdRouters/group/${groupId}`;
      } else if (perfilId === '4') {
        apiEndpoint = `https://echo.tsmpatagonia.com.ar:3001/api/stDeviceIdRouters/user/${userId}`;
      }

      try {
        const response = await axios.get(apiEndpoint, {
          headers: {
            accept: 'application/json',
          },
        });

        // Map the API response to the required format for DataGrid
        const mappedRows = response.data.map((item, index) => ({
          id: index,
          DeviceName: item.sli_nickname || item.uti_kitSerialNumber,
          userTerminalId: item.uti_userTerminalId,
          kitSerialNumber: item.uti_kitSerialNumber,
          serviceLineNumber: item.sli_serviceLineNumber,
          active: item.uti_active === 1 ? 'Active' : 'Inactive',
          downlinkThroughput: item.ut_DownlinkThroughput,
          uplinkThroughput: item.ut_UplinkThroughput,
          pingLatency: item.ut_PingLatencyMsAvg,
          uptime: (item.ut_Uptime / 3600).toFixed(2), // Convert uptime from seconds to hours
          signalQuality: item.ut_SignalQuality,
          obstructionPercent: item.ut_ObstructionPercentTime,
          productReferenceId: item.sli_productReferenceId,
          nickname: item.sli_nickname,
          addressId : item.sli_addressReferenceId,
          stardate : item.sli_startDate,
          publicIp: item.sli_publicIp === 1 ? 'Yes' : 'No',
          AccountName: item.ca_ClientName,
          AccountNumber: item.ca_AccountNumber
        }));

        setRows(mappedRows);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching user terminals:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Define columns for the DataGrid, including ut and sli fields
  const columns = [
    { field: 'Kit Name', headerName: 'Nickname', width: 220 ,
      renderCell: (params) => (
        <Button
          variant="outlined"
          onClick={() => navigate(`/dashboard`, { state: { deviceId: params.row.userTerminalId } })}
          sx={{
            width: '100%',
            whiteSpace: 'normal',  // Allows text to wrap
            wordWrap: 'break-word',  // Breaks long words to wrap properly
            textAlign: 'center', // Center the text if needed
          }}
        >
          {params.row.DeviceName}
        </Button>
      ),
    },
    { field: 'kitSerialNumber', headerName: 'Kit Serial Number', width: 150 },
    { field: 'AccountName', headerName: 'Account Name', width: 150 },
    { field: 'AccountNumber', headerName: 'Account Number', width: 150 },
    { field: 'serviceLineNumber', headerName: 'Service Line Number', width: 180,
      renderCell: (params) => (
        <span style={{ fontWeight: 'bold' }}>
          {params.value}
        </span>
      )
     },
    { field: 'productReferenceId', headerName: 'Product Reference ID', width: 250 },
    { field: 'stardate', headerName: 'Start Date', width: 200,
      renderCell: (params) => (
        <span style={{  }}>
          {formatDate(params.row.stardate)}
        </span>
      )
     },
    { field: 'active', headerName: 'Active', width: 110 },

    
    

  
  ];

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ height: '90vh', width: '100%' }}>
      <DataGrid rows={rows} columns={columns} pageSize={10} rowHeight={80} sx = {{border:0}} rowsPerPageOptions={[10, 25, 50]} />
    </Box>
  );
};

export default UserTerminals;
