import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, MenuItem, FormControl, Select, InputLabel, TablePagination } from '@mui/material';

const Reports = () => {
  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState('');
  const [logs, setLogs] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  useEffect(() => {
    axios.get('https://echo.tsmpatagonia.com.ar:3001/api/deviceInfo/')
      .then(response => {
        setDevices(response.data);
      })
      .catch(error => {
        console.error('Error fetching device data:', error);
      });
  }, []);

  useEffect(() => {
    if (selectedDevice) {
      axios.get(`https://echo.tsmpatagonia.com.ar:3001/api/logs/${selectedDevice}`)
        .then(response => {
          // Ordenar logs por ID de manera descendente
          const sortedLogs = response.data.sort((a, b) => b.id - a.id);
          setLogs(sortedLogs);
        })
        .catch(error => {
          console.error('Error fetching logs:', error);
        });
    }
  }, [selectedDevice]);

  const handleChange = (event) => {
    setSelectedDevice(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <Typography variant="h5" gutterBottom>
        Logs de registros por ESN
      </Typography>
      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <InputLabel id="device-select-label">Select Device</InputLabel>
        <Select
          labelId="device-select-label"
          value={selectedDevice}
          label="Select Device"
          onChange={handleChange}
        >
          {devices.map(device => (
            <MenuItem key={device.deviceEsn} value={device.deviceEsn}>
              {`${device.deviceEsn} - ${device.deviceName}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TableContainer component={Paper}>
        <Table sx = {{fontSize: '0.675rem'}}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>ID</TableCell>
              <TableCell>Timestamp</TableCell>
              <TableCell>Data</TableCell>
              <TableCell>Payload</TableCell>
              {/* Agrega más encabezados según la estructura de datos de la API */}
            </TableRow>
          </TableHead>
          <TableBody> 
            {logs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(log => (
              <TableRow key={log.id} sx = {{fontSize: '0.675rem'}}>
                <TableCell sx={{ fontSize: '0.675rem', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{log.esn}</TableCell>
                <TableCell sx={{ fontSize: '0.675rem', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{log.fixtime}</TableCell>
                <TableCell sx = {{fontSize: '0.675rem'}}>{log.column_13}</TableCell>
                <TableCell sx = {{fontSize: '0.675rem'}}>{log.payload}</TableCell>
                {/* Agrega más celdas según la estructura de datos de la API */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={logs.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  );
};

export default Reports;
