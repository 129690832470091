import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, FormControl, InputLabel, MenuItem, Select, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

const Sensors = () => {
  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(''); // Inicializar con una cadena vacía
  const [sensorInfo, setSensorInfo] = useState([]);
  const [selectedSensor, setSelectedSensor] = useState(''); // Inicializar con una cadena vacía
  const [sensorData, setSensorData] = useState([]); // Estado para almacenar los datos del sensor

  useEffect(() => {
    // Solicitud para obtener la lista de dispositivos
    axios.get('https://echo.tsmpatagonia.com.ar:3001/api/deviceInfo/')
      .then(response => {
        setDevices(response.data);
      })
      .catch(error => {
        console.error('Error fetching devices:', error);
      });
  }, []);

  useEffect(() => {
    if (selectedDevice) {
      // Solicitud para obtener la información de los sensores del dispositivo seleccionado
      axios.get(`https://echo.tsmpatagonia.com.ar:3001/api/sensor-info/${selectedDevice}`)
        .then(response => {
          setSensorInfo(response.data);
        })
        .catch(error => {
          console.error('Error fetching sensor info:', error);
        });
    }
  }, [selectedDevice]);

  useEffect(() => {
    if (selectedDevice && selectedSensor) {
      // Solicitud para obtener los datos del sensor seleccionado
      axios.get(`https://echo.tsmpatagonia.com.ar:3001/api/sensor-data/${selectedDevice}/${selectedSensor}`)
        .then(response => {
          setSensorData(response.data);
        })
        .catch(error => {
          console.error('Error fetching sensor data:', error);
        });
    }
  }, [selectedDevice, selectedSensor]);

  const handleDeviceChange = (event) => {
    setSelectedDevice(event.target.value);
    setSelectedSensor(''); // Reiniciar la selección de sensores al cambiar el dispositivo
    setSensorData([]); // Reiniciar los datos del sensor
  };

  const handleSensorChange = (event) => {
    setSelectedSensor(event.target.value);
  };

  const formatChartData = () => {
    return {
      labels: sensorData.map(data => new Date(data.created_at).toLocaleString()), // Convertir a formato legible
      datasets: [
        {
          label: 'Sensor Data',
          data: sensorData.map(data => data.Data), // Usar el campo de datos correcto
          fill: false,
          backgroundColor: 'rgba(75,192,192,0.4)',
          borderColor: 'rgba(75,192,192,1)',
        },
      ],
    };
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Sensors
      </Typography>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="device-select-label">Select Device</InputLabel>
        <Select
          labelId="device-select-label"
          value={selectedDevice || ''}
          label="Select Device"
          onChange={handleDeviceChange}
        >
          {devices.map(device => (
            <MenuItem key={device.deviceEsn} value={device.deviceEsn}>
              {`${device.deviceEsn} - ${device.deviceName}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="sensor-select-label">Select Sensor</InputLabel>
        <Select
          labelId="sensor-select-label"
          value={selectedSensor || ''}
          label="Select Sensor"
          onChange={handleSensorChange}
          disabled={!selectedDevice}
        >
          {sensorInfo.map(sensor => (
            <MenuItem key={sensor.id} value={sensor.id}>
              {`${sensor.sensorType} - ${sensor.sensorName}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {sensorData.length > 0 && (
        <>
          <Box sx={{ mb: 3 }}>
            <Line data={formatChartData()} />
          </Box>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Timestamp</TableCell>
                  <TableCell>Value</TableCell>
                  {/* Añade más columnas según los datos disponibles */}
                </TableRow>
              </TableHead>
              <TableBody>
                {sensorData.map((data) => (
                  <TableRow key={data.id}>
                    <TableCell>{data.id}</TableCell>
                    <TableCell>{new Date(data.created_at).toLocaleString()}</TableCell>
                    <TableCell>{data.Data}</TableCell>
                    {/* Añade más celdas según los datos disponibles */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Box>
  );
};

export default Sensors;
