import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const Sensors = () => {
  const [sensors, setSensors] = useState([]);

  useEffect(() => {
    // Realiza la solicitud a la API para obtener los datos de los sensores
    axios.get('https://echo.tsmpatagonia.com.ar:3001/api/deviceInfo/')
      .then(response => {
        setSensors(response.data);
      })
      .catch(error => {
        console.error('Error fetching sensor data:', error);
      });
  }, []);

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Sensors
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>ESN</TableCell>
              <TableCell>Creado</TableCell>
              {/* Agrega más encabezados según la estructura de datos de la API */}
            </TableRow>
          </TableHead>
          <TableBody>
            {sensors.map(sensor => (
              <TableRow key={sensor.id}>
                <TableCell>{sensor.deviceId}</TableCell>
                <TableCell>{sensor.deviceName}</TableCell>
                <TableCell>{sensor.deviceEsn}</TableCell>
                <TableCell>{sensor.created_at}</TableCell>
                {/* Agrega más celdas según la estructura de datos de la API */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Sensors;
