// Users.js
import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Box,
  Typography,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from '@mui/material';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';

const Users = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [formData, setFormData] = useState({
    id: '',
    nombre: '',
    email: '',
    password: '',
    perfil_id: '',
    grupo_id: '',
  });

  const [groupData, setGroupData] = useState({
    id: '',
    nombre: '',
    parent_group_id: '',
  });

  const token = localStorage.getItem('token');

  useEffect(() => {
    fetchUsers();
    fetchGroups();
  }, []);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://echo.tsmpatagonia.com.ar:3001/api/users', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUsers(response.data);
    } catch (err) {
      setError('Error al cargar los usuarios');
    } finally {
      setLoading(false);
    }
  };

  const fetchGroups = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://echo.tsmpatagonia.com.ar:3001/api/groups', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setGroups(response.data);
    } catch (err) {
      setError('Error al cargar los grupos');
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleGroupInputChange = (e) => {
    const { name, value } = e.target;
    setGroupData({ ...groupData, [name]: value });
  };

  const handleAddUser = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.post('https://echo.tsmpatagonia.com.ar:3001/api/users', formData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      fetchUsers(); // Refresh user list
    } catch (err) {
      setError('Error al crear el usuario');
    } finally {
      setLoading(false);
    }
  };

  const handleEditUser = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.put('https://echo.tsmpatagonia.com.ar:3001/api/users', formData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      fetchUsers(); // Refresh user list
    } catch (err) {
      setError('Error al actualizar el usuario');
    } finally {
      setLoading(false);
    }
  };

  const handleAddGroup = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.post('https://echo.tsmpatagonia.com.ar:3001/api/groups', groupData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      fetchGroups(); // Refresh group list
    } catch (err) {
      setError('Error al crear el grupo');
    } finally {
      setLoading(false);
    }
  };

  const handleEditGroup = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.put('https://echo.tsmpatagonia.com.ar:3001/api/groups', groupData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      fetchGroups(); // Refresh group list
    } catch (err) {
      setError('Error al actualizar el grupo');
    } finally {
      setLoading(false);
    }
  };

  const handleEditClick = (user) => {
    setFormData(user);
  };

  const handleEditGroupClick = (group) => {
    setGroupData(group);
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Gestión de Usuarios y Grupos
      </Typography>
      {error && <Typography color="error">{error}</Typography>}

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Crear/Editar Usuario</Typography>
          <Box component="form" onSubmit={formData.id ? handleEditUser : handleAddUser}>
            <TextField
              fullWidth
              margin="normal"
              label="Nombre"
              name="nombre"
              value={formData.nombre}
              onChange={handleInputChange}
              required
            />
            <TextField
              fullWidth
              margin="normal"
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
            <TextField
              fullWidth
              margin="normal"
              label="Password"
              name="password"
              type="password"
              value={formData.password}
              onChange={handleInputChange}
              required={!formData.id} // Password required only when creating
            />
            <TextField
              fullWidth
              margin="normal"
              label="Perfil ID"
              name="perfil_id"
              value={formData.perfil_id}
              onChange={handleInputChange}
              required
            />
            <TextField
              fullWidth
              margin="normal"
              label="Grupo ID"
              name="grupo_id"
              value={formData.grupo_id}
              onChange={handleInputChange}
            />
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{ mt: 2 }}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : formData.id ? 'Actualizar Usuario' : 'Crear Usuario'}
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h6">Crear/Editar Grupo</Typography>
          <Box component="form" onSubmit={groupData.id ? handleEditGroup : handleAddGroup}>
            <TextField
              fullWidth
              margin="normal"
              label="Nombre del Grupo"
              name="nombre"
              value={groupData.nombre}
              onChange={handleGroupInputChange}
              required
            />
            <TextField
              fullWidth
              margin="normal"
              label="ID del Grupo Padre"
              name="parent_group_id"
              value={groupData.parent_group_id}
              onChange={handleGroupInputChange}
            />
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{ mt: 2 }}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : groupData.id ? 'Actualizar Grupo' : 'Crear Grupo'}
            </Button>
          </Box>
        </Grid>
      </Grid>

      {/* Table for Users */}
      <Box sx={{ mt: 4 }}>
        <Typography variant="h6">Usuarios Existentes</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Perfil ID</TableCell>
                <TableCell>Grupo ID</TableCell>
                <TableCell>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>{user.nombre}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.perfil_id}</TableCell>
                  <TableCell>{user.grupo_id}</TableCell>
                  <TableCell>
                    <IconButton edge="end" aria-label="edit" onClick={() => handleEditClick(user)}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Table for Groups */}
      <Box sx={{ mt: 4 }}>
        <Typography variant="h6">Grupos Existentes</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nombre del Grupo</TableCell>
                <TableCell>ID del Grupo Padre</TableCell>
                <TableCell>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {groups.map((group) => (
                <TableRow key={group.id}>
                  <TableCell>{group.nombre}</TableCell>
                  <TableCell>{group.parent_group_id}</TableCell>
                  <TableCell>
                    <IconButton edge="end" aria-label="edit" onClick={() => handleEditGroupClick(group)}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default Users;
