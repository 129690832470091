import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Drawer, Box, Button, Grid, useTheme, Avatar, IconButton } from '@mui/material';
import { deepPurple } from '@mui/material/colors';
import SatelliteAltIcon from '@mui/icons-material/SatelliteAlt';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonIcon from '@mui/icons-material/Person';
import PublicIcon from '@mui/icons-material/Public';
import DashboardIcon from '@mui/icons-material/Dashboard';
import MapIcon from '@mui/icons-material/Map';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import ContrastIcon from '@mui/icons-material/Contrast';
import LogoutIcon from '@mui/icons-material/Logout';

const Sidebar = ({ isOpen, toggleTheme }) => {
  const groupId = localStorage.getItem('groupId');
  const userId = localStorage.getItem('userId');
  const name = localStorage.getItem('name');
  const lastName = localStorage.getItem('lastName');
  const avatarBlob = localStorage.getItem('avatar');  // Get the avatar blob data
  console.log(avatarBlob)
  const [imageUrl, setImageUrl] = useState(null);

  const [expanded, setExpanded] = useState(true); // State to handle sidebar expansion
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const perfilId = parseInt(localStorage.getItem('perfilId'), 10); // Get perfilId from localStorage

  // Function to convert the blob to a URL when the avatar is available
  useEffect(() => {
    if (avatarBlob) {
      //const blobUrl = URL.createObjectURL(new Blob([avatarBlob])); // Create a URL from the blob
      const blobUrl = `data:image/jpeg;base64,${avatarBlob}`;
      setImageUrl(blobUrl);
      // Clean up the object URL when the component unmounts to avoid memory leaks
      return () => URL.revokeObjectURL(blobUrl);
    }
  }, [avatarBlob]);

  // Function to handle logout
  const handleLogout = () => {
    // Clear any authentication-related data from localStorage
    localStorage.removeItem('userId');
    // Navigate to the login page
    navigate('/login');
  };

  const menuItems = [
    { text: 'Service List', path: '/', icon: <SatelliteAltIcon /> },
    { text: 'Info DashBoard', path: '/dashboard', icon: <DashboardIcon /> },
    { text: 'Global Map', path: '/maps', icon: <MapIcon /> },
    { text: 'Alarms', path: '/alarm', icon: <NotificationImportantIcon /> },
    { text: 'Terminals', path: '/userterminals', icon: <PublicIcon /> },
    ...(perfilId === 1
      ? [
          { text: 'Routers', path: '/routersadmin', icon: <SettingsIcon /> },
          { text: 'User Settings', path: '/useradmin', icon: <PersonIcon /> },
        ]
      : []),
  ];

  // Toggle the sidebar's expanded state
  const handleToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: expanded ? { xs: '50vw', sm: '30vw', md: '20vw', lg: '15vw' } : { xs: '15vw', sm: '10vw', md: '8vw', lg: '5vw' }, // Responsive width
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: expanded ? { xs: '50vw', sm: '30vw', md: '20vw', lg: '15vw' } : { xs: '15vw', sm: '10vw', md: '8vw', lg: '5vw' }, // Responsive width for paper
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          overflow: 'hidden',
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        },
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Grid container direction="column" spacing={1} sx={{ p: 2 }}>
          {menuItems.map((item) => (
            <Grid item key={item.text}>
              <Button
                fullWidth
                variant="contained"
                size="medium"
                color={location.pathname === item.path ? 'secondary' : 'inherit'}
                onClick={() => navigate(item.path)}
                sx={{
                  justifyContent: expanded ? 'flex-start' : 'center',
                  backgroundColor: location.pathname === item.path ? 'secondary.main' : 'transparent',
                  color: location.pathname === item.path ? '#fff' : 'inherit',
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                }}
                startIcon={expanded ? item.icon : null}
              >
                {expanded ? item.text : item.icon}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box
        sx={{
          p: 2,
          display: 'flex',
          justifyContent: expanded ? 'space-between' : 'center',
          alignItems: 'center',
          flexDirection: expanded ? 'row' : 'column', // Change to vertical when collapsed
          gap: 1, // Add spacing between icons when stacked
        }}
      >
        <Avatar src={imageUrl} alt={`${name} ${lastName}`} sx={{ width: 56, height: 56, border:1 }} />
        <IconButton onClick={toggleTheme}>
          <ContrastIcon />
        </IconButton>
        <IconButton onClick={handleLogout}>
          <LogoutIcon />
        </IconButton>
        <IconButton onClick={handleToggle}>
          <SwapHorizIcon />
        </IconButton>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
