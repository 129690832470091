import React from 'react';
import { AppBar, Toolbar, Typography, Box, IconButton, useMediaQuery, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ContrastIcon from '@mui/icons-material/Contrast'; // Import the ContrastIcon
import LogoutIcon from '@mui/icons-material/Logout'; // Import the LogoutIcon
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation

const TopBar = ({ toggleSidebar, toggleTheme }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate(); // Hook to handle navigation

  // Function to handle logout
  const handleLogout = () => {
    // Clear any authentication-related data from localStorage
    localStorage.removeItem('userId');
    // Navigate to the login page
    navigate('/login');
  };

  // Determine which logo to use based on the current theme mode
  const logoSrc = theme.palette.mode === 'dark'
    ? 'https://tsmpatagonia.com.ar/wp-content/uploads/2023/07/Recurso-2.png'
    : 'https://tsmpatagonia.com.ar/wp-content/uploads/2023/07/Recurso-1.png';

  return (
    <AppBar position="static">
      <Toolbar>
        {isSmallScreen && (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleSidebar}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
        )}
        <Typography variant="h6" noWrap sx={{ flexGrow: 1, fontSize: isSmallScreen ? '1rem' : '1.5rem' }}>
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          
          <Box
            component="img"
            src={logoSrc}
            alt="StarLink Logo"
            sx={{ ml: 2, height: 40, objectFit: 'contain' }} // Adjust the height as needed
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
