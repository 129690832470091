import React, { useEffect, useState } from 'react';
import { Box, Paper, Typography, Grid, useMediaQuery, MenuItem, Select, FormControl, InputLabel, Stack, CircularProgress, useTheme } from '@mui/material';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import axios from 'axios';
import { Gauge } from '@mui/x-charts/Gauge';
import { LinearProgress } from '@mui/material';
import * as h3 from 'h3-js';
import { useLocation } from 'react-router-dom';

function formatUptime(seconds) {
  const days = Math.floor(seconds / (24 * 3600)); // Calculate total days
  const hours = Math.floor((seconds % (24 * 3600)) / 3600); // Calculate remaining hours
  const minutes = Math.floor((seconds % 3600) / 60); // Calculate remaining minutes
  const secs = seconds % 60; // Calculate remaining seconds

  return `${days}d:${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}

function formatDate(isoString) {
  const date = new Date(isoString);
  return date.toLocaleDateString();  // This will return the date in the user's locale format
}


mapboxgl.accessToken = 'pk.eyJ1IjoiZnJvY2NvYXIiLCJhIjoiY20wbXo4N3FvMDMwbzJrb21kOXhsY3FlbyJ9.chVdQIlwUlzcsAvuJsbx8g';

// De internet mapboxgl.accessToken = 'pk.eyJ1IjoiZXhhbXBsZXVzZXIiLCJhIjoiY2tsdWh2amJvMDB2ajJ2bzBxd3BicXh5NSJ9.5y1t8W8bHyzVf0stYJ1R3A';


const Dashboard = () => {
  const location = useLocation();
  const selectedDeviceId = location.state?.deviceId || ''; // Get the deviceId from state or use a default value

  const [map, setMap] = useState(null);
  const [address, setAddress] = useState(null);


  const [devices, setDevices] = useState([]); 
  const [selectedDevice, setSelectedDevice] = useState(selectedDeviceId); // Initialize with selectedDeviceId

  const [dishData, setDishData] = useState({});
  const [position, setPosition] = useState([-67.53045749329654, -24.02252433841831]); // Default position
  
 
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const [alerts, setAlerts] = useState([]);
  const [loading, setLoading] = useState(false);
  const parseActiveAlert = (alertString) => {
    // Eliminar corchetes y convertir en array de números
    const alertArray = alertString.replace(/[\[\]]/g, '').split(',').map(Number);
    return alertArray.filter(code => !isNaN(code));
  };
  

  useEffect(() => {
    const perfilId = localStorage.getItem('perfilId');
    const groupId = localStorage.getItem('groupId');
    const userId = localStorage.getItem('userId');
  
    let apiEndpoint;
    if (perfilId === '1' || perfilId === '2') {
      apiEndpoint = 'https://echo.tsmpatagonia.com.ar:3001/api/stDeviceIdRouters';
    } else if (perfilId === '3') {
      apiEndpoint = `https://echo.tsmpatagonia.com.ar:3001/api/stDeviceIdRouters/group/${groupId}`;
    } else if (perfilId === '4') {
      apiEndpoint = `https://echo.tsmpatagonia.com.ar:3001/api/stDeviceIdRouters/user/${userId}`;
    }

    if (apiEndpoint) {
      axios.get(apiEndpoint)
        .then(response => {
          setDevices(response.data);
          if (selectedDeviceId) {
            setSelectedDevice(selectedDeviceId);
          } else {
            setSelectedDevice(response.data[0]?.uti_userTerminalId || '');
          }
        })
        .catch(error => {
          console.error('Error fetching devices:', error);
        });
    }
  }, [selectedDeviceId]);
  

  const fetchData = () => {
    if (selectedDevice) {
      axios.get(`https://echo.tsmpatagonia.com.ar:3001/api/stDeviceIdRouters/userterminal/${selectedDevice}`)
      .then(dishResponse => {
          const responseData = dishResponse.data[0];
          //console.log('Response Data:', responseData); // Log the full response to verify
          setDishData(responseData); // Set the state with the response
          //console.log('Selected Device:', selectedDevice); // This will log correctly

          if (dishResponse.data[0]?.ut_H3CellId) {
            const H3CellIdHex = parseInt(dishResponse.data[0].ut_H3CellId).toString(16);
            const [lat, lon] = h3.cellToLatLng(H3CellIdHex);
            setPosition([lon, lat]);
            console.log([lon, lat])
          }

        })
        .catch(error => {
          console.error('Error fetching device data:', error);
        });
    }
  };

  useEffect(() => {
    fetchData(); // Fetch initially

    const interval = setInterval(() => {
      fetchData(); // Fetch every 15 seconds
    }, 15000);

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [selectedDevice]);

  useEffect(() => {
    const mapInstance = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/froccoar/clzru7x0100fn01pbf08t4v1v',
      zoom: 10,
      pitch: 45,
      bearing: 0,
    });

    mapInstance.on('style.load', () => {
      new mapboxgl.Marker({ color: '#1976d2' })
        .setLngLat(position)
        .setPopup(new mapboxgl.Popup().setText('Ultima Posición conocida'))
        .addTo(mapInstance);

      mapInstance.setCenter(position); // Move the center after style is loaded
      mapInstance.addControl(new mapboxgl.NavigationControl());
      setMap(mapInstance);

      let bearing = 0;
      let isRotating = true;

      function rotateCamera() {
        if (isRotating) {
          bearing += 0.1;
          mapInstance.rotateTo(bearing, { duration: 0 });
          requestAnimationFrame(rotateCamera);
        }
      }

      rotateCamera();

      mapInstance.on('mousedown', () => {
        isRotating = false;
      });
      
    })


    

    return () => mapInstance.remove();
  }, []);

  useEffect(() => {
    if (map && position) {
      map.setCenter(position);

      if (dishData?.ut_H3CellId) {
        const H3CellIdHex = parseInt(dishData.ut_H3CellId).toString(16);
        const hexBoundary = h3.cellToBoundary(H3CellIdHex);

        const geoJsonPolygon = {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [hexBoundary.map(coord => [coord[1], coord[0]])] // Map to [lon, lat]
          }
        };

        if (map.getSource('hexagon')) {
          map.getSource('hexagon').setData(geoJsonPolygon);
        } else {
          map.addSource('hexagon', {
            type: 'geojson',
            data: geoJsonPolygon
          });

          map.addLayer({
            id: 'hexagon',
            type: 'fill',
            source: 'hexagon',
            layout: {},
            paint: {
              'fill-color': '#099',
              'fill-opacity': 0.3
            }
          });
        }
      }
    }
  }, [position, map, dishData]);

  const [onlineStatus, setOnlineStatus] = useState(true); // State to hold online/offline status
  const [buenosAiresTime, setBuenosAiresTime] = useState(''); // State to store converted time


  const checkOnlineStatus = (UtcTimestampNs) => {
    const timestampMs = UtcTimestampNs / 1e6;
    const buenosAiresTimeString = new Date(timestampMs).toLocaleString("en-US", {
      timeZone: "America/Argentina/Buenos_Aires",
      hour12: false,
    });

    const buenosAiresDate = new Date(buenosAiresTimeString);
    const currentDate = new Date().toLocaleString("en-US", {
      timeZone: "America/Argentina/Buenos_Aires",
      hour12: false,
    });

    const currentDateObj = new Date(currentDate);
    const timeDifference = Math.abs(currentDateObj - buenosAiresDate);
    const isOnline = timeDifference < 600000; // 2 minute

    // Return both the time string and the online status
    return {
      buenosAiresTime: buenosAiresTimeString,
      isOnline: isOnline
    };
  };



  useEffect(() => {
  if (dishData?.ut_UtcTimestampNs) {
    const { buenosAiresTime, isOnline } = checkOnlineStatus(dishData?.ut_UtcTimestampNs);
    //console.log(`Device Time in Buenos Aires: ${buenosAiresTime}`);
    //console.log(`Device is ${isOnline ? 'Online' : 'Offline'}`);
    setOnlineStatus(isOnline); // Update the state
    setBuenosAiresTime(buenosAiresTime); // Use the correct variable here
  }

  const fetchAlarmData = async () => {
    // Si el dispositivo no tiene alarmas o está vacío, vaciar el estado de alertas
    if (!dishData?.ut_ActiveAlert || dishData.ut_ActiveAlert === '[]') {
      setAlerts([]);  // Vaciar el estado de alertas
      setLoading(false);  // Terminar la carga
      return;
    }

    setLoading(true);

    // Parseamos los códigos de alerta
    const alertCodes = parseActiveAlert(dishData.ut_ActiveAlert);

    try {
      // Para cada código, hacemos una petición a la API
      const alertPromises = alertCodes.map(async (code) => {
        const response = await axios.get(`https://echo.tsmpatagonia.com.ar:3001/api/alarmdata/${code}`);
        return response.data;
      });

      // Esperamos a que todas las promesas se resuelvan
      const alertData = await Promise.all(alertPromises);

      // Guardamos los datos en el estado
      setAlerts(alertData[0]);
    } catch (error) {
      console.error('Error fetching alarm data:', error);
    } finally {
      setLoading(false);
    }
  };

  fetchAlarmData();

  const fetchAddressData = async () => {
    // Si no existe 'sli_addressReferenceId' o está vacío, vaciar el estado de dirección
    if (!dishData?.sli_addressReferenceId || dishData.sli_addressReferenceId === '') {
      setLoading(false);  // Terminar la carga
      setAddress(null);  // Limpia los datos de la dirección
      return;
    }
  
    setLoading(true);  // Inicia la carga
  
    const code = dishData.sli_addressReferenceId;  // Obtiene el código de referencia de la dirección
  
    try {
      // Llamada a la API para obtener los datos de la dirección
      const response = await axios.get(`https://echo.tsmpatagonia.com.ar:3001/api/address/${code}`);
  
      // Verifica si hay datos válidos en la respuesta
      if (response.data && response.data.length > 0) {
        const addressData = response.data[0];  // Toma el primer objeto de los datos
        setAddress(addressData);  // Actualiza el estado con todos los campos del objeto
      } else {
        setAddress(null);  // En caso de que no haya datos
      }
    } catch (error) {
      console.error('Error fetching address data:', error);
      setAddress(null);  // Limpia los datos si hay error
    } finally {
      setLoading(false);  // Finaliza la carga
    }
  };
  
  // Ejecución de la función para cargar los datos de dirección
  fetchAddressData();
  


}, [dishData]);



  return (
    <Box sx={{ height: '80vh', display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Grid container spacing={2} sx={{ flex: '1 1 auto', height: '100%' }}>
        {/* Left Panel */}
        <Grid item xs={5}> {/* Ensanché esta columna */}
          <Stack container direction="column" spacing={0} sx={{ height: '100%' }}>
            {/* Device Selector */}
            <Grid item sx={{ flex: '1 1 auto' }}>
              <FormControl fullWidth>
                <InputLabel id="device-select-label">Device</InputLabel>
                <Select
                  labelId="device-select-label"
                  id="device-select"
                  value={selectedDevice}
                  label="Device"
                  onChange={(e) => setSelectedDevice(e.target.value)}
                >
                  {devices.map((device) => (
                    <MenuItem key={device.uti_userTerminalId} value={device.uti_userTerminalId}>
                      {device.sli_nickname || device.uti_kitSerialNumber}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Device Status */}
            <Grid item sx={{ flex: '1 1 auto' }}>
              <Paper elevation={0} sx={{ p: 2, height: '100%' }}>
                <Typography variant={isSmallScreen ? "body2" : "h6"}>Device Status</Typography>
                {dishData ? (
                  <>
                    <Typography variant={isSmallScreen ? "body2" : "h10"} sx={{ fontWeight: 'bold' }}>
                      {onlineStatus ? 'Online ' : 'Offline '}
                    </Typography>
                    <Typography variant={isSmallScreen ? "body2" : "h12"} sx={{ color: 'text.secondary', fontWeight: 'bold' }}>
                      Last connection {buenosAiresTime || 'Unknown'}
                    </Typography>
                  </>
                ) : <CircularProgress />}
              </Paper>
            </Grid>

            {/* Uplink and Downlink Gauges in one row */}
            <Grid container spacing={2}>
              {/* Uplink Gauge */}
              <Grid item xs={6}>
                <Paper elevation={2} sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', borderRadius: "0" }}>
                  <Typography variant={isSmallScreen ? "body2" : "h6"} sx={{ fontWeight: 'bold' }}>Uplink</Typography>
                  <Typography variant={isSmallScreen ? "body2" : "h10"} sx={{ color: 'text.secondary' }}>Upload speed</Typography>
                  {dishData ? (
                    <Gauge
                      innerRadius="60%"
                      outerRadius="100%"
                      width={200}
                      height={200}
                      valueMax={400}
                      value={dishData?.ut_UplinkThroughput || 0}
                      startAngle={-110}
                      endAngle={110}
                      text={() => `${dishData?.ut_UplinkThroughput || 0} Mbps`}
                      sx={{ mx: 'auto' }}
                    />
                  ) : <CircularProgress />}
                </Paper>
              </Grid>

              {/* Downlink Gauge */}
              <Grid item xs={6}>
                <Paper elevation={2} sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', borderRadius: "0" }}>
                  <Typography variant={isSmallScreen ? "body2" : "h6"} sx={{ fontWeight: 'bold' }}>Downlink</Typography>
                  <Typography variant={isSmallScreen ? "body2" : "h10"} sx={{ color: 'text.secondary' }}>Download speed</Typography>
                  {dishData ? (
                    <Gauge
                      innerRadius="60%"
                      outerRadius="100%"
                      width={200}
                      height={200}
                      valueMax={400}
                      value={dishData?.ut_DownlinkThroughput || 0}
                      startAngle={-110}
                      endAngle={110}
                      text={() => `${dishData?.ut_DownlinkThroughput || 0} Mbps`}
                      sx={{ mx: 'auto' }}
                    />
                  ) : <CircularProgress />}
                </Paper>
              </Grid>
            </Grid>
          </Stack>
        </Grid>

        {/* Map Section */}
        <Grid item xs={7}> {/* Disminuí el tamaño del mapa */}
          <Paper elevation={2} sx={{ p: 0, height: '100%', width: '100%', borderRadius: "0" }}>
            <Box id="map" sx={{ height: '100%', width: '100%', borderRadius: "0" }} />
          </Paper>
        </Grid>
      </Grid>

     

      {/* Network Stats */}
      <Grid container spacing={2} sx={{ flex: '1 1 20%' }}>
        {['Ping Latency', 'Clients', 'WAN Tx', 'WAN Rx', 'Ping Drop', 'Uptime'].map((label, index) => (
          <Grid item xs={2} key={label}>
            <Paper elevation={2} sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'top', borderRadius: "0" }}>
              <Typography variant={isSmallScreen ? "body2" : "h6"} sx={{ fontWeight: 'bold' }}>{label}</Typography>
              {dishData ? (
                <Typography variant={isSmallScreen ? "body2" : "h5"} sx={{ color: 'text.secondary', fontWeight: 'bold' }}>
                  {label === 'Ping Latency' && `${dishData?.r_InternetPingLatencyMs || 0} ms`}
                  {label === 'Clients' && `${dishData?.r_Clients || 0}`}
                  {label === 'WAN Tx' && `${(dishData?.r_WanTxBytes / (1024 * 1024 * 1024)).toFixed(2) || 0} GB`}
                  {label === 'WAN Rx' && `${(dishData?.r_WanRxBytes / (1024 * 1024 * 1024)).toFixed(2) || 0} GB`}
                  {label === 'Ping Drop' && `${dishData?.r_InternetPingDropRate || 0} %`}
                  {label === 'Uptime' && `${formatUptime(dishData?.ut_Uptime) || 0}`}
                </Typography>
              ) : <CircularProgress />}
            </Paper>
          </Grid>
        ))}
      </Grid>
       {/* Lower Panel with various metrics */}
      <Grid container spacing={2} sx={{ flex: '1 1 40%', }}>
        <Grid item xs={4}>
          <Paper elevation={2} sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'top', borderRadius: "0" }}>
          <Typography variant={isSmallScreen ? "body2" : "h6"} sx={{ fontWeight: 'bold' }}>Signal Quality</Typography>
            {dishData ? (
              <Box sx={{ width: '100%', mt: 2 }}>
                <Typography level="body-xs" textColor = "white" variant={isSmallScreen ? "body2" : "h6"} align="center">
                  {Math.round(dishData?.ut_SignalQuality * 100)}%
                </Typography>
                {/* Barra horizontal */}
                <LinearProgress
                  variant="determinate"
                  size="sm"
                  thickness={32}
                  value={dishData?.ut_SignalQuality * 100 || 0}  // Multiplicamos por 10 para obtener el porcentaje correcto
                  sx={{ height: 25, borderRadius: 0 }}
                >
                
                </LinearProgress>
              </Box>
            ) : (
              <CircularProgress />
            )}
          </Paper>
        </Grid>

        <Grid item xs={4}>
          <Paper elevation={2} sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'top', borderRadius: "0" }}>
          <Typography variant={isSmallScreen ? "body2" : "h6"} sx={{ fontWeight: 'bold' }}>Alerts</Typography>
            {loading ? (
              <CircularProgress />
            ) : (
              <>
    
                {alerts.length > 0 ? (
                  alerts.map((alert, index) => (
                    
                    <Typography key={index} variant={isSmallScreen ? "body2" : "h10"} sx={{ color: 'text.secondary', fontWeight: 'bold' }}>
                      [{alert.code}] {alert.human_readable_description || `Alert Code ${alert.code}`}  {/* Mostramos la descripción o el código */}
                    </Typography>
                  ))
                ) : (
                  <Typography variant={isSmallScreen ? "body2" : "h6"} sx={{ color: 'text.secondary', fontWeight: 'bold' }}>
                    No alarms
                  </Typography>
                )}
              </>
            )}
          </Paper>
        </Grid>

        <Grid item xs={4}>
          <Paper elevation={2} sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'top', borderRadius: "0" }}>
            {dishData ? (
                 <>
                  <Box sx={{ display: 'flex', alignItems: 'end', gap: 1 }}>
                    {/* Envolviendo los elementos en un fragmento */}
                    <Typography variant={isSmallScreen ? "body2" : "h6"} sx={{ fontWeight: 'bold' }}>Dish Id:</Typography> 
                    <Typography variant={isSmallScreen ? "body2" : "h10"} sx={{ color: 'text.secondary', fontWeight: 'bold' }}>ut{dishData?.uti_userTerminalId}</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'end', gap: 1 }}>

                    <Typography variant={isSmallScreen ? "body2" : "h6"} sx={{  fontWeight: 'bold' }}>Router Id:</Typography> 
                    <Typography variant={isSmallScreen ? "body2" : "h10"} sx={{ color: 'text.secondary', fontWeight: 'bold' }}>{dishData?.r_DeviceId}</Typography>
                  </Box>      
                  <Box sx={{ display: 'flex', alignItems: 'end', gap: 1 }}>
                    <Typography variant={isSmallScreen ? "body2" : "h6"} sx={{  fontWeight: 'bold' }}>Service Line number:</Typography> 
                    <Typography variant={isSmallScreen ? "body2" : "h10"} sx={{ color: 'text.secondary', fontWeight: 'bold' }}>{dishData?.uti_serviceLineNumber}</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'end', gap: 1 }}>
                    <Typography variant={isSmallScreen ? "body2" : "h6"} sx={{  fontWeight: 'bold' }}>KitNumber:</Typography> 
                    <Typography variant={isSmallScreen ? "body2" : "h10"} sx={{ color: 'text.secondary', fontWeight: 'bold' }}>{dishData?.uti_kitSerialNumber  || 'N/A'}</Typography>
                  </Box>
               </>
            ) : <CircularProgress />}
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ flex: '1 1 40%' , mb: 4}}>
          <Grid item xs={6}>
            <Paper elevation={2} sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'top', borderRadius: "0" }}>
              {address ? (
                  <>
                    <Box sx={{ display: 'flex', alignItems: 'end', gap: 1 }}>
                      {/* Envolviendo los elementos en un fragmento */}
                      <Typography variant={isSmallScreen ? "body2" : "h6"} sx={{ fontWeight: 'bold' }}>Region:</Typography> 
                      <Typography variant={isSmallScreen ? "body2" : "h10"} sx={{ color: 'text.secondary', fontWeight: 'bold' }}>{address.region}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'end', gap: 1 }}>
                      <Typography variant={isSmallScreen ? "body2" : "h6"} sx={{  fontWeight: 'bold' }}>Locality:</Typography> 
                      <Typography variant={isSmallScreen ? "body2" : "h10"} sx={{ color: 'text.secondary', fontWeight: 'bold' }}>{address.locality}</Typography>
                    </Box>      
                    <Box sx={{ display: 'flex', alignItems: 'end', gap: 1 }}>
                      <Typography variant={isSmallScreen ? "body2" : "h6"} sx={{  fontWeight: 'bold' }}>Addess Line:</Typography> 
                      <Typography variant={isSmallScreen ? "body2" : "h10"} sx={{ color: 'text.secondary', fontWeight: 'bold' }}>{address.addressLines}</Typography>
                    </Box>
                    
                </>
              ) : <CircularProgress />}
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper elevation={2} sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'top', borderRadius: "0" }}>
              {address ? (
                  <>
                    <Box sx={{ display: 'flex', alignItems: 'end', gap: 1 }}>
                      {/* Envolviendo los elementos en un fragmento */}
                      <Typography variant={isSmallScreen ? "body2" : "h6"} sx={{ fontWeight: 'bold' }}>Account:</Typography> 
                      <Typography variant={isSmallScreen ? "body2" : "h10"} sx={{ color: 'text.secondary', fontWeight: 'bold' }}>{dishData?.ca_AccountNumber} / {dishData?.ca_ClientName}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'end', gap: 1 }}>
                      <Typography variant={isSmallScreen ? "body2" : "h6"} sx={{  fontWeight: 'bold' }}>Product:</Typography> 
                      <Typography variant={isSmallScreen ? "body2" : "h10"} sx={{ color: 'text.secondary', fontWeight: 'bold' }}>{dishData?.sli_productReferenceId}</Typography>
                    </Box>      
                    <Box sx={{ display: 'flex', alignItems: 'end', gap: 1 }}>
                      <Typography variant={isSmallScreen ? "body2" : "h6"} sx={{  fontWeight: 'bold' }}>Start date:</Typography> 
                      <Typography variant={isSmallScreen ? "body2" : "h10"} sx={{ color: 'text.secondary', fontWeight: 'bold' }}>{formatDate(dishData?.sli_startDate)}</Typography>
                    </Box>
                    
                </>
              ) : <CircularProgress />}
            </Paper>
          </Grid>
      </Grid>
      
    </Box>
    );
};

export default Dashboard;
